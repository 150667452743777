<template>
<div id="internal">

  <div class="hidden-sm-and-down">
    <PageHeader/>
  </div>
  <div class="hidden-md-and-up">
    <m-top/>
  </div>

  <div class="hidden-xs-only">
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">

        <div style="width: 100%; max-width: 1284px; margin: 0 auto;">
          <div class="side-box" style="margin-bottom: 30px;">
            <div class="title">ヒト幹細胞培養上清液​（Stemsup）</div>
          </div>

          

          <div class="separator">
            <div class="img_banner">
              <img style="width: 100%;" src="../../assets/departments/stemsup/StemSup_01.png">
              <div class="black_background">
                <div style="color: #ffffff; font-size: 40px;">StemSup®</div>
                <div style="color: #ffffff; font-size: 30px;">ヒト幹細胞培養上清液</div>
                <div style="margin-top: 30px;">
                  <div style="color: #ffffff; font-size: 20px;">Stemsup®は株式会社日本バイオセラピー<br>研究所の登録商標です。</div>
                  <div style="color: #ffffff; font-size: 20px; margin-top: 15px;">※StemSup®は研究用試薬です。医師の指<br>示に基づいて使用します</div>
                </div>
                
              </div>
            </div>

            <div class="content1">
              
              <div class="bg_black" style="padding-top: 30px; padding-bottom: 30px;">
                <div class="side-text">
                  <div class="slogan_big_l" style="color:#CBB8B2;">
                    ヒト幹細胞培養上清液ステムサップ（StemSup®）とは
                  </div>
                </div>
                

                <div class="content1">
                  <div class="side-text">
                  <div class="side-box-img">
                    <div style="width: 60%;">

                      <div class="text1">ヒトの幹細胞を培養して、そこから分泌される活性物質を含む液のこと。</div>
                      <div class="text1">この上澄み液には、幹細胞から分泌されるエクソソームや100種類以上の成長因子・サイトカインなどの生理活性物質が豊富に含まれています。</div>
                      <div class="text1">ステムサップの特徴は、幹細胞から分泌された活性物質（エクソソーム、成長因子、サイトカインなど）のみを含み、外から加えた活性物質を含まないナチュラルな配合の上清液であることです。このサイトカインを投与することで、年齢とともに衰えた細胞の修復・再生を促し、さまざまな健康回復・増進と美容に対する効果が期待できます。</div>
                      
                    </div>
                    <div class="side-img" style="width: 40%;">
                      <img src="../../assets/departments/stemsup/StemSup_02.png" class="fit_img">
                    </div>
                  </div>              
                  </div>
                </div>


                
                <div class="content1">
                  <div class="side-box">   
                    <div class="side-text">
                      <div class="text1">ご提供するヒト幹細胞培養上清液ステムサップ（StemSup®）は国内屈指の細胞加工施設において、厳密かつ多重の検査を実施し、高い品質基準をクリアした製品のみを使用しております。</div> 
                    </div>     
                  </div>
                </div>

                <div class="content_text">
                  <div class="side-box" style="width: 100%;">
                    <div class="side-img" style="width:30%;margin-left: 2.5%;position: relative;">
                      <img src="../../assets/departments/stemsup_zhifang.png" style="width: 100%;">
                      <div class="show_content_in_image" style="position: absolute;">
                        <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">StemSup-A®</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">◆脂肪由来幹細胞
                          <br>育毛・美容に</div>
                      </div>
                    </div>
                    <div class="side-img" style="width:30%;margin-left: 2.5%;position: relative;">
                      <img src="../../assets/departments/stemsup_yasui.png" style="width: 100%;margin-left: 0 auto;">
                      <div class="show_content_in_image" style="position: absolute;">
                        <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">StemSup-Dp®</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">◆歯髄由来幹細胞
                          <br>神経系疾患痛みなどに</div>
                      </div>
                    </div>
                    <div class="side-img" style="width:30%;margin-left: 2.5%;margin-right: 2.5%;position: relative;">
                      <img src="../../assets/departments/stemsup_qidai.png" style="width: 100%; margin-left: 0 auto;"> 
                      <div class="show_content_in_image" style="position: absolute;">
                        <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">StemSup-UC®</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">◆臍帯由来幹細胞
                          <br>強い抗炎症効果をもつ</div>
                      </div>
                    </div>
                  </div>
                </div>




                <div class="content1">
                  
                  <div class="side-text">
                    <div class="title" style="color:#CBB8B2;">
                      StemSup®に含まれる主な活性物質
                    </div>
                  </div>
                  
                  <div class="content1">
                    <div class="show_list">
                                          
                      <div class="show_content">
                        <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">エクソソーム</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">様々な組繊修復の促進</div>
                      </div>

                      <div class="show_content">
                        <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">HGF</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">粗職再生活性</div>
                      </div>

                      <div class="show_content">
                        <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">BDNF</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">神経細の再生</div>
                      </div>

                      <div class="show_content">
                        <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">TGF-B</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">抗炎症創傷治癒</div>
                      </div>

                    </div>
                  </div>

                  <div class="content1">
                  <div class="show_list">
                                        
                    <div class="show_content">
                      <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">I G F</div>
                      <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">皮膚再生ハリ・弾力アップ</div>
                    </div>

                    <div class="show_content">
                      <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">P D G F</div>
                      <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">細胞分裂の促進</div>
                    </div>

                    <div class="show_content">
                      <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">VEGE</div>
                      <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">ハリ・弾力アップ血行<br>改善発毛促進</div>
                    </div>

                    <div class="show_content">
                      <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">KGF.</div>
                      <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">発毛、育毛</div>
                    </div>

                  </div>
                </div>
                </div>

                

                
              </div>







              <div class="slogan_large"></div>

              <div class="side-box" style="height: 200px; overflow: hidden;">
                <div style="position:absolute;width: 80%;left: 10%;">
                  <div class=" slogan_large font_border">こんな方におすすめ</div>
                </div>
              </div>

              <div class="bg_blue" style=" padding-top: 30px;padding-bottom: 30px;">
                  <div class="side-box-img" style="width: 100%;height: 100%;">
                    <div class="side-img" style="width: 50%;">
                      <img src="../../assets/departments/stemsup/StemSup_03.jpg" class="fit_img">
                    </div>
                    <div class="line_text_box" style="width: 55%;">
                      <div class="line_text" style="margin-bottom: 3%;">
                        加齢による肌の衰えに抗いたい
                      </div>
                      <div class="line_text" style="margin-bottom: 3%;">
                        若々しさ・活力を取り戻したい
                      </div>
                      <div class="line_text" style="margin-bottom: 3%;">
                        様々な疾患によるダメージの回復を図りたい
                      </div>
                      <div class="line_text">
                        加齢による肌の衰えに抗いたい
                      </div>
                      
                    </div>
                    
                  </div>
              </div>

              <div class="bg_green" style="padding-top: 30px;padding-bottom: 30px;">


                <div class="side-text">
                  <div class="slogan_big_l" style="color:#CBB8B2;">StemSup®の安全性について</div>
                </div>



                <div class="content1">
                  <div class = "side-text">
                    <div class="side-box-img">
                      <div style="width: 60%;">
                        <div class="text1">当院は数ある幹細胞培養上清液の中でも株式会社日本バイオセラピー研究所で試薬として製造されたStemSup®（ヒト幹細胞培養上清液）を医師の責任において患者様の同意のもとで使用しております。</div>
                        <div class="text1">株式会社日本バイオセラピー社は、厚生労働大臣に許可された細胞培養加工施設です。</div>
                        <div class="text1">また、Stemsup®は製品ロットごとに含有する生理活性物質などを測定し、高い品質基準をクリアした製品のみをご提供しております。しかし現在の検査方法では検知出来ない未知のウイルスの存在を完全に否定できるものではありません。</div>
                        <div class="text1">その為、輸血やプラセンタ投与後と同じようにステムサップの点滴投与を受けた以降は、献血をしないように注意してください。</div>
                      </div>
                      <div class="side-img" style="width:40%;margin-right: 0px;">
                        <img src="../../assets/departments/stemsup/StemSup_04.jpg" class="fit_img">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="content1" style="height: 50px;"></div>
                <div class="side-text">
                  <div class="title" style="color:#CBB8B2;">StemSup®点滴療法の適応とならない方</div>
                </div>
                <div class="content1">
                  <div class="side-box">
                    <div class="side-text">

                      <div class="text1">・妊娠中や授乳中の方</div>
                      <div class="text1">・薬物のアレルギーがある方</div>
                      <div class="text1">・がんや悪性腫瘍を治療中の方</div>
                      <div class="text1">　特にがんを患っている場合は、正常な細胞だけでなく、がん細胞まで活性化させてしまう恐れが否定できないからです。</div>
                      <div class="text1">・また、アレルギーや服用中の薬がある方は、治療前に医師への申告・相談が必要になります。</div>
                      
                    </div>
                  </div>
                </div>


                
              </div>

              <div class="side-box" style="height: 200px; overflow: hidden; padding-top: 30px; padding-bottom: 30px;">
                <div style="position:absolute;width: 90%;left: 5%;">
                  <div class=" slogan_large font_border">お問い合わ・ご予約は</div>
                  <div class=" slogan_large font_border">お電話にてお待ちしております</div>
                  <div class=" slogan_large font_border phone_text">０３－６２６０－６００８</div>
                </div>
              </div>
              <div class="bg_blue" style="height: fit-content; padding-top: 30px;padding-bottom: 30px;">
                <table class="table_ryoukin">
                  <tr>
                    <td><div class="ryoukin_yellow_top" style=" margin-left: 5%;">幹細胞エクソソーム（脂肪）</div></td>
                    <td><div class="ryoukin_yellow_top" style=" margin-left: 25%;">幹細胞エクソソーム（臍帯）</div></td>
                  </tr>
                  <tr>
                    <td class="ryoukin_line"><div class="ryoukin_name" style="margin-left: 5%;">点滴１アンプル</div></td>
                    <td><div class="ryoukin_name" style=" margin-left: 25%;">点滴１アンプル</div></td>
                  </tr>
                  <tr>
                    <td class="ryoukin_line"><div class="ryoukin_price" style="margin-left: 35%;">５５，０００円</div></td>
                    <td><div class="ryoukin_price" style=" margin-left: 55%;">７７，０００円</div></td>
                  </tr>
                  <tr>
                    <td class="ryoukin_line"><div class="ryoukin_name" style="margin-left: 5%;">点滴３アンプル</div></td>
                    <td><div class="ryoukin_name" style=" margin-left: 25%;">点滴３アンプル</div></td>
                  </tr>
                  <tr>
                    <td class="ryoukin_line"><div class="ryoukin_price" style="margin-left: 35%;">１４８，５００円</div></td>
                    <td><div class="ryoukin_price" style=" margin-left: 55%;">２１４，５００円</div></td>
                  </tr>
                  <tr>
                    <td class="ryoukin_line"><div class="ryoukin_name" style="margin-left: 5%;">点滴１０アンプル</div></td>
                    <td><div class="ryoukin_name" style=" margin-left: 25%;">点滴１０アンプル</div></td>
                  </tr>
                  <tr>
                    <td><div class="ryoukin_price" style="margin-left: 35%;">４４０，０００円</div></td>
                    <td><div class="ryoukin_price" style=" margin-left: 55%;">６６０，０００円</div></td>
                  </tr>
                </table>
              </div>

            </div>
          </div>


      </div>
      </el-col>
    </el-row>
    <PageFooter/>
  </div>

  <div class="hidden-sm-and-up">
    <div style="width: 100%; max-width: 1284px; margin: 0 auto;">
          <div class="side-box" style="margin-bottom: 30px;">
            <div class="title">ヒト幹細胞培養上清液​（Stemsup）</div>
          </div>

          

          <div class="separator">
            <div class="img_banner">
              <div style="position: absolute;width: 100%;">
                
              </div>
              <img style="width: 100%;" src="../../assets/departments/stemsup/StemSup_01.png">
              <div class="black_background">
                <div style="color: #ffffff; font-size: 25px;">StemSup®</div>
                <div style="color: #ffffff; font-size: 15px;">ヒト幹細胞培養上清液</div>
                <div style="margin-top: 10px;">
                  <div style="color: #ffffff; font-size: 12px;">Stemsup®は株式会社日本バイオセラピー<br>研究所の登録商標です。</div>
                  <div style="color: #ffffff; font-size: 12px; margin-top: 10px;">※StemSup®は研究用試薬です。医師の指<br>示に基づいて使用します</div>
                </div>
                
              </div>
            </div>
              
              <div class="bg_black" style="padding-top: 30px; padding-bottom: 30px;">
                <div class="m_side-text">
                  <div class="slogan_big_l" style="color:#CBB8B2;">
                    ヒト幹細胞培養上清液<br>ステムサップ(StemSup®)とは
                  </div>
                </div>
                

                <div class="content1">
                  <div class="m_side-text">
                  <div>
                    <div>

                      <div class="text1">ヒトの幹細胞を培養して、そこから分泌される活性物質を含む液のこと。</div>
                      <div class="text1">この上澄み液には、幹細胞から分泌されるエクソソームや100種類以上の成長因子・サイトカインなどの生理活性物質が豊富に含まれています。</div>
                      <div class="text1">ステムサップの特徴は、幹細胞から分泌された活性物質（エクソソーム、成長因子、サイトカインなど）のみを含み、外から加えた活性物質を含まないナチュラルな配合の上清液であることです。このサイトカインを投与することで、年齢とともに衰えた細胞の修復・再生を促し、さまざまな健康回復・増進と美容に対する効果が期待できます。</div>
                      
                    </div>
                    <div class="side-img">
                      <img src="../../assets/departments/stemsup/StemSup_02.png" class="fit_img">
                    </div>
                  </div>              
                  </div>
                </div>


                
                <div class="content1">
                  <div class="side-box">   
                    <div class="m_side-text">
                      <div class="text1">ご提供するヒト幹細胞培養上清液ステムサップ（StemSup®）は国内屈指の細胞加工施設において、厳密かつ多重の検査を実施し、高い品質基準をクリアした製品のみを使用しております。</div> 
                    </div>     
                  </div>
                </div>

                <div class="content_text">
                  <div class="side-box" style="width: 100%;height: 200px;">
                    <div class="side-img" style="width:30%;height: 100%;margin-left: 1.25%;margin-right: 1.25%;position: relative;">
                      <img src="../../assets/departments/stemsup_zhifang.png" class="fit_img">
                      <div class="show_content_in_image" style="position: absolute;">
                        <div class="show_text" style="color: #4f7573; font-size: 18px; margin-top: 10px;">StemSup-A®</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 11px; margin-left: 10%; margin-top: 10px;">◆脂肪由来幹細胞
                          <br>育毛・美容に</div>
                      </div>
                    </div>
                    <div class="side-img" style="width:30%;height: 100%;margin-left: 1.25%;margin-right: 1.25%;position: relative;">
                      <img src="../../assets/departments/stemsup_yasui.png"  class="fit_img">
                      <div class="show_content_in_image" style="position: absolute;">
                        <div class="show_text" style="color: #4f7573; font-size: 18px; margin-top: 10px;">StemSup-Dp®</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 11px; margin-left: 10%; margin-top: 10px;">◆歯髄由来幹細胞
                          <br>神経系疾患痛みなどに</div>
                      </div>
                    </div>
                    <div class="side-img" style="width:30%;height: 100%;margin-left: 1.25%;margin-right: 1.25%;position: relative;">
                      <img src="../../assets/departments/stemsup_qidai.png"  class="fit_img"> 
                      <div class="show_content_in_image" style="position: absolute;">
                        <div class="show_text" style="color: #4f7573; font-size: 18px; margin-top: 10px;">StemSup-UC®</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 11px; margin-left: 10%; margin-top: 10px;">◆臍帯由来幹細胞
                          <br>強い抗炎症効果をもつ</div>
                      </div>
                    </div>
                  </div>
                </div>




                <div class="content1">
                  
                  <div class="m_side-text">
                    <div class="slogan_big_l" style="color:#CBB8B2;">
                      StemSup®に含まれる<br>主な活性物質
                    </div>
                  </div>
                  
                  <div class="content1">
                    <div class="m_show_list">
                                          
                      <div class="m_show_content">
                        <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">エクソソーム</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">様々な組繊修復の促進</div>
                      </div>

                      <div class="m_show_content">
                        <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">HGF</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">粗職再生活性</div>
                      </div>
                    </div>
                    <div class="m_show_list">
                      <div class="m_show_content">
                        <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">BDNF</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">神経細の再生</div>
                      </div>

                      <div class="m_show_content">
                        <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">TGF-B</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">抗炎症創傷治癒</div>
                      </div>

                    </div>

                    <div class="m_show_list">
                                          
                      <div class="m_show_content">
                        <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">I G F</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">皮膚再生ハリ・弾力アップ</div>
                      </div>

                      <div class="m_show_content">
                        <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">P D G F</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">細胞分裂の促進</div>
                      </div>
                    </div>
                    <div class="m_show_list">
                      <div class="m_show_content">
                        <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">VEGE</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">ハリ・弾力アップ血行<br>改善発毛促進</div>
                      </div>

                      <div class="m_show_content">
                        <div class="show_text" style="color: #4f7573; font-size: 25px; margin-top: 30px;">KGF.</div>
                        <div class="show_text" style="color: #535353; width: 80%; font-size: 13px; margin-left: 10%; margin-top: 10px;">発毛、育毛</div>
                      </div>

                    </div>
                </div>
                </div>

                

                
              </div>







              <div class="slogan_large"></div>

              <div class="side-box" style="height: 150px; overflow: hidden;">
                <div style="position:absolute;width: 80%;left: 10%;">
                  <div class=" slogan_big_l font_border">こんな方におすすめ</div>
                </div>
              </div>

              <div class="bg_blue" style=" padding-top: 30px;padding-bottom: 30px;height: auto">
                  <div style="width: 100%;">
                    <div class="side-img" style="height: 400px;">
                      <img src="../../assets/departments/stemsup/StemSup_03.jpg" class="fit_img">
                    </div>
                    <div class="line_text_box" style="margin-top: 10px; height: 271px">
                      <div class="m_line_text" style="height:45px;margin-bottom: 20px;">
                        加齢による肌の衰えに抗いたい
                      </div>
                      <div class="m_line_text" style="height:45px;margin-bottom: 20px;">
                        若々しさ・活力を取り戻したい
                      </div>
                      <div class="m_line_text" style="height:45px;margin-bottom: 20px;">
                        様々な疾患によるダメージの回復を図りたい
                      </div>
                      <div class="m_line_text" style="height:45px;">
                        加齢による肌の衰えに抗いたい
                      </div>
                      
                    </div>
                    
                  </div>
              </div>

              <div class="bg_green" style="padding-top: 30px;padding-bottom: 30px;">


                <div class="m_side-text">
                  <div class="slogan_big_l" style="color:#CBB8B2;">StemSup®の<br>安全性について</div>
                </div>



                <div class="content1">
                  <div class = "m_side-text">
                    <div>
                      <div>
                        <div class="text1">当院は数ある幹細胞培養上清液の中でも株式会社日本バイオセラピー研究所で試薬として製造されたStemSup®（ヒト幹細胞培養上清液）を医師の責任において患者様の同意のもとで使用しております。</div>
                        <div class="text1">株式会社日本バイオセラピー社は、厚生労働大臣に許可された細胞培養加工施設です。</div>
                        <div class="text1">また、Stemsup®は製品ロットごとに含有する生理活性物質などを測定し、高い品質基準をクリアした製品のみをご提供しております。しかし現在の検査方法では検知出来ない未知のウイルスの存在を完全に否定できるものではありません。</div>
                        <div class="text1">その為、輸血やプラセンタ投与後と同じようにステムサップの点滴投与を受けた以降は、献血をしないように注意してください。</div>
                      </div>
                      <div class="side-img">
                        <img src="../../assets/departments/stemsup/StemSup_04.jpg" class="fit_img">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="content1" style="height: 10px;"></div>
                <div class="m_side-text">
                  <div class="slogan_big_l" style="color:#CBB8B2;">StemSup®点滴療法の<br>適応とならない方</div>
                </div>
                <div class="content1">
                  <div class="side-box">
                    <div class="m_side-text">

                      <div class="text1">・妊娠中や授乳中の方</div>
                      <div class="text1">・薬物のアレルギーがある方</div>
                      <div class="text1">・がんや悪性腫瘍を治療中の方</div>
                      <div class="text1">　特にがんを患っている場合は、正常な細胞だけでなく、がん細胞まで活性化させてしまう恐れが否定できないからです。</div>
                      <div class="text1">・また、アレルギーや服用中の薬がある方は、治療前に医師への申告・相談が必要になります。</div>
                      
                    </div>
                  </div>
                </div>


                
              </div>

              <div class="side-box" style="height: 150px; overflow: hidden;">
                <div style="position:absolute;width: 80%;left: 10%;">
                  <div class=" m_slogan_large font_border">お問い合わ・ご予約は</div>
                  <div class=" m_slogan_large font_border">お電話にてお待ちしております</div>
                  <div class=" m_slogan_large font_border phone_text" onclick="window.location.href='tel:0362606008'">０３－６２６０－６００８</div>
                </div>
              </div>
              <div class="bg_blue" style="height: fit-content; padding-top: 30px;padding-bottom: 30px;">
                <table class="table_ryoukin">
                  <tr>
                    <td><div class="m_ryoukin_yellow_top" style="">幹細胞エクソソーム（脂肪）</div></td>
                  </tr>
                  <tr>
                    <td><div class="m_ryoukin_name" style="margin-left: 5%;">点滴１アンプル</div></td>
                  </tr>
                  <tr>
                    <td><div class="m_ryoukin_price" style="margin-left: 5%;">５５，０００円</div></td>
                  </tr>
                  <tr>
                    <td><div class="m_ryoukin_name" style="margin-left: 5%;">点滴３アンプル</div></td>
                  </tr>
                  <tr>
                    <td><div class="m_ryoukin_price" style="margin-left: 5%;">１４８，５００円</div></td>
                  </tr>
                  <tr>
                    <td><div class="m_ryoukin_name" style="margin-left: 5%;">点滴１０アンプル</div></td>
                  </tr>
                  <tr>
                    <td><div class="m_ryoukin_price" style="margin-left: 5%;">４４０，０００円</div></td>
                  </tr>
                  <tr>
                    <td><div class="m_ryoukin_yellow_top" style="margin-top: 15px;">幹細胞エクソソーム（臍帯）</div></td>
                  </tr>
                  <tr>
                    <td><div class="m_ryoukin_name" style=" margin-left: 5%;">点滴１アンプル</div></td>
                  </tr>
                  <tr>
                    <td><div class="m_ryoukin_price" style=" margin-left: 5%;">７７，０００円</div></td>
                  </tr>
                  <tr>
                    <td><div class="m_ryoukin_name" style=" margin-left: 5%;">点滴３アンプル</div></td>
                  </tr>
                  <tr>
                    <td><div class="m_ryoukin_price" style=" margin-left: 5%;">２１４，５００円</div></td>
                  </tr>
                  <tr>
                    <td><div class="m_ryoukin_name" style=" margin-left: 5%;">点滴１０アンプル</div></td>
                  </tr>
                  <tr>
                    <td><div class="m_ryoukin_price" style=" margin-left: 5%;">６６０，０００円</div></td>
                  </tr>
                </table>
              </div>

          </div>


      </div>

    <m-departs/>
    <m-footer/>
  </div>
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import DepartmentAside from "@/components/DepartmentAside";
import MTop from "../../components/m/MTop";
import MFooter from "../../components/m/MFooter";
import MDeparts from "../../components/m/MDeparts";
import DietSidebar from "@/components/DietSidebar";
export default {
  name: "Stemsup",
  components: {MDeparts, MFooter, MTop, DepartmentAside, PageFooter, PageHeader,DietSidebar}
}
</script>

<style scoped>
/* PC */
.hidden-sm-and-down .img {
  margin-top: 30px;
}
.detail1 {
  margin: 40px 20px;
}
.content1 {
  margin-top: 40px;
}
.content1 .title1 {
  color: #CBB8B2;
  font-size: 18px;
  margin-bottom: 10px;
}
.text1 {
  color: #ebebeb;
  line-height: 1.5;
  margin-bottom: 15px;
  }
.other {
  color: #535353;
  font-size: 14px;
  line-height: 1.5;
}

/* SP */
.m-main {
  background-color: #FFFFFF;
  border-top: 2px solid #CBB8B2;
  border-bottom: 2px solid #CBB8B2;
  padding: 2rem 0;
}
.m-title {
  color: #535353;
  font-size: 24px;
  text-align: center;
  margin: 2rem 0;
}
.m-detail {
  background-color: #FFFFFF;
  padding: 0 1rem;
}
.m-subtitle {
  color: #CBB8B2;
  font-size: 18px;
  padding: 2rem 0 1rem;
}
.m-content {
  line-height: 24px;
  color: #535353;
}

.center-title{
  color: #535353;
  font-size: 24px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.LINE_btn{
  background-color: #20ce88;
  border-radius: 5px; 
  color: #FFFFFF;
  width:300px;
  height:50px;
  position: absolute;
  right: 0px;
  margin-right: 22%;
}

.banner_slogan{

  text-align: center;
  color: #326686;
  text-shadow: 1px 2px 0px #e9d5ce6b,
    0px 2px 3px #326686c4
    ;
  width: 100%;
}

.slogan_small{
      font-size: 15px;
      margin-top: 5px;
    }
    
    .slogan_big{
      font-size: 18px;
      margin-top: 15px;
    }
    
    .slogan_big_l{
      font-size: 30px;
      margin-top: 15px;
    }
    
    .slogan_large{
      font-size: 50px;  
    }

.m_slogan_small{
  font-size: 5px;
  margin-top: 5px;
}

.m_slogan_big{
  font-size: 10px;
  margin-top: 5px;
}

.m_slogan_big_l{
  font-size: 12px;
  margin-top: 5px;
}

.m_slogan_large{
  font-size: 20px;  
}

.font_border{
  text-align: center;
  color: #326686;
  text-shadow: 
    0px 1px 4px #f5efe3,
    0px -1px 4px #f5efe3,
    1px 0px 4px #f5efe3,
    -1px 0px 4px #f5efe3;
  width: 100%;
}

.img_banner{
  position: relative;
  height: auto;
}

.table_ryoukin{
  font-size: 22px;
  color: #ffffff;
  width: 90%;
  margin: 0 auto;
  border-collapse:collapse;
}


.table_ryoukin tr{
  height:30px
}

.ryoukin_top{
  width: 25%;
}

.ryoukin_name_top{
  font-size: 24px;
  text-align: center;
}
.ryoukin_line{
  border-right:3px solid #ffffff;
}

.ryoukin_name{
  vertical-align: middle;
  color:#CBB8B2;
  width: 70%;
  height:30px;
}
.m_ryoukin_name{
  vertical-align: middle;
  color:#CBB8B2;
  width: 90%;
  height:30px;
  font-size: 20px;
}
.ryoukin_price{
  vertical-align: middle;
  color:#eec72e;
  width: 40%;
  height:30px;
  text-align: right;
}
.m_ryoukin_price{
  vertical-align: middle;
  color:#eec72e;
  width: 65%;
  height:30px;
  text-align: right;
  font-size: 20px;
}

.ryoukin_yellow_top{
  width: 70%;
  height:40px;
  text-align: center;
  border-radius: 5px;
  line-height: 1.8;
  margin-bottom: 5px;
  background-color: #eec72e;
  color: #ffffff;

}
.m_ryoukin_yellow_top{
  width: 100%;
  height:40px;
  text-align: center;
  border-radius: 5px;
  line-height: 2;
  margin-bottom: 5px;
  background-color: #eec72e;
  color: #ffffff;

}

.bg_black{
  background-color: #4d4f4f;
  width: 100%;
  height:fit-content;
  padding-bottom: 100px;
}

.bg_blue{
  background-color: #326686;
  width: 100%;
  height: 300px;
}

.bg_green{
  background-color: #5a7977;
  width: 100%;
  height: auto;
}

.ryoukin_hint{
  color: #F71B1B;
  font-size: 16px;
  text-align: right;
  padding-right: 50px;
}

.title {
  font-size: 30px;
  letter-spacing: 5px;
  padding-top: 20px;
}

.separator {
      color: #CBB8B2;
      width: 100%;
      margin: 0 auto;
      border-top: 4px solid;
      border-bottom: 4px solid;
    }

.side-img{
    height: auto;
    width:auto;
    vertical-align:middle;
    margin-left: 30px;
    margin-right: 30px;
    }
  
    .side-text{
    height: fit-content;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: auto;
    }
    .m_side-text{
    height: fit-content;
    margin-left: 7%;
    margin-right: 7%;
    width: 86%;
    margin-bottom: auto;
    }

.side-text-line{
  height: fit-content;
}



.side-box{
      display: inline-flex;
      justify-content: space-between;
      align-items:center;
      vertical-align: top;
      height: auto;
    }
  
    .side-box-img{
      display: inline-flex;
      justify-content: space-between;
      align-items:top;
      vertical-align: top;
      height: auto;
    }

    .fit_img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .seperate_line{
      height:5px;
      border-top-color: #CBB8B2;
      border-top-width: 2px;
      border-top-style: solid;
      margin: 0 auto;
      width: 100%;
    }
    .content_text{
        margin-top: 18px;
    }

.side-line-highlight{
  background-color: #CBB8B2;
  width:6px;
  height: 25px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.show_content{
  background-color: #FFFFFF;
  width: 20%;
  height:120px;
  margin-left: 1%;
}
.m_show_content{
  background-color: #FFFFFF;
  width: 45%;
  height:160px;
  margin-left: 1%;
  vertical-align: middle;
}

.show_content_in_image{
  background-color: #ffffffb9;
  width: 90%;
  height:70%;
  top:15%;
  left:5%;
  text-shadow: 1px 1px 4px #3b5d9c,
  1px 1px 0px #e5f5ffd4;
}

.m_show_content_in_image{
  background-color: #ffffffb9;
  width: 90%;
  height:60%;
  top:20%;
  left:5%;
  text-shadow: 1px 1px 4px #3b5d9c,
  1px 1px 0px #e5f5ffd4;
}

.show_list{
  width: 90%;
  margin-left: 5%;
  height: fit-content;
  display: inline-flex;
  justify-content: space-between;
  align-items:center;
  vertical-align: top;
}
.m_show_list{
  width: 90%;
  margin-left: 5%;
  height: fit-content;
  display: inline-flex;
  justify-content: space-between;
  align-items:center;
  vertical-align: top;
  margin-top: 20px;
}

.show_text{
  width:100%;
  text-align: center;
}

.line_text{
  background: #ffffff;
  color: #3f6583;
  font-weight: bold;
  width: 80%;
  height: 15%;
  margin-left: 5%;
  border-radius: 5px;
  text-align: left;
  padding-left: 10%;
  padding-bottom: 1%;
  line-height: 3;
}
.m_line_text{
  background: #ffffff;
  color: #3f6583;
  font-weight: bold;
  width: 85%;
  height: 15%;
  margin-left: 5%;
  border-radius: 5px;
  text-align: left;
  padding-left: 5%;
  padding-bottom: 1%;
  line-height: 3.5;
  font-size: 14px;
}

.line_text_box{
  width: 100%;
  height: 100%;
}

.phone_text{
  color: #eec72e;
}

.black_background{
  background-color: #00000051;
  width: fit-content;
  height: fit-content;
  padding: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
}
</style>
